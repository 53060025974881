// import 'scripts/wdyr'
import React, { useEffect } from 'react';
import '../styles/base.sss';
import { COOKIES } from 'consts';
import AppSSR from 'containers/AppSSR';
import { AppContextProvider } from 'contexts/appContext';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { bool, shape } from 'prop-types';
import { types } from 'redux-act';
import { cookieService } from 'services';
import { isInApp } from 'utils/isInApp';
import initSentry from 'utils/sentry/sentry';
import { isDevelop, sentryEnabled } from 'utils/stages';

import { ThemeProvider } from '@s7/styles';

// fix hot module replacement
// https://github.com/pauldijou/redux-act/issues/42
if (isDevelop) {
    types.disableChecking();
}

if (sentryEnabled) {
    initSentry();
}

const AppCointainer = dynamic(() => import('../containers/App'), {
    loading: AppSSR,
    ssr: false,
});

const MyApp = props => {
    const { inApp, isWebpSupport, pageProps } = props;

    useEffect(() => {
        if (inApp && cookieService.get(COOKIES.IN_APP) !== 'true') {
            cookieService.set(COOKIES.IN_APP, 'true');
        }
    }, [inApp]);

    return (
        <ThemeProvider>
            <AppContextProvider value={{ inApp, isWebpSupport }}>
                {pageProps && pageProps.Unlogged ? (
                    <AppSSR {...pageProps} />
                ) : (
                    <AppCointainer {...props} />
                )}
            </AppContextProvider>
        </ThemeProvider>
    );
};

MyApp.propTypes = {
    inApp: bool,
    pageProps: shape({
        Unlogged: bool,
    }),
};

MyApp.getInitialProps = async appContext => {
    const appProps = await App.getInitialProps(appContext);

    return {
        ...appProps,
        inApp: isInApp(appContext.ctx),
        isWebpSupport:
            appContext.ctx?.req?.headers?.accept?.includes('image/webp'),
    };
};

export default MyApp;
